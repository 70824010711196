import {FC} from 'react';

export interface SpinnerProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const Spinner: FC<SpinnerProps> = ({fullWidth = false, fullHeight = false, ...props}) => {
  const classNames = ['display-flex'];

  if (fullWidth) {
    classNames.push('w-100 flex-justify-center');
  }

  if (fullHeight) {
    classNames.push('h-100 flex-align-center');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
