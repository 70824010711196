import {createContext, FC} from 'react';
import {CraftWebsiteRepository} from '../repositories/craftWebsiteRepository';
import {WordpressWebsiteRepository} from '../repositories/wordpressWebsiteRepository';
import {UserRepository} from '../repositories/userRepository';

export interface RepositoriesContextReturn {
  craftWebsites: CraftWebsiteRepository;
  wordpressWebsites: WordpressWebsiteRepository;
  users: UserRepository;
}

const defaultValue: RepositoriesContextReturn = {
  craftWebsites: new CraftWebsiteRepository(),
  wordpressWebsites: new WordpressWebsiteRepository(),
  users: new UserRepository(),
};
export const RepositoriesContext = createContext<RepositoriesContextReturn>(defaultValue);

// @ts-ignore
const RepositoriesProvider: FC = ({children}) => (
  <RepositoriesContext.Provider value={defaultValue}>
    {children}
  </RepositoriesContext.Provider>
);

export default RepositoriesProvider;
