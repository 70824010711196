import {QueryDocumentSnapshot} from 'firebase/firestore';
import {BaseRepository} from './baseRepository';
import {CraftWebsite} from "../models/craftWebsite";

export class CraftWebsiteRepository extends BaseRepository<CraftWebsite> {
    constructor() {
        super('craft-websites');
    }

    // eslint-disable-next-line class-methods-use-this
    fromFirestore(snapshot: QueryDocumentSnapshot): CraftWebsite {
        return new CraftWebsite({
            id: snapshot.id,
            ...snapshot.data(),
        } as CraftWebsite);
    }
}
