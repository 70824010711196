/* eslint-disable class-methods-use-this */
import {
  ActionCodeInfo, applyActionCode, checkActionCode, CompleteFn, confirmPasswordReset,
  createUserWithEmailAndPassword, EmailAuthProvider, ErrorFn, getAuth, NextOrObserver, onAuthStateChanged,
  reauthenticateWithCredential, signInWithEmailAndPassword, signOut,
  Unsubscribe, User as AuthUser, UserCredential,
} from 'firebase/auth';
import {QueryDocumentSnapshot} from 'firebase/firestore';
import {BaseRepository} from './baseRepository';
import {IUser,} from '../interfaces/user';
import {User} from '../models/user';

export class UserRepository extends BaseRepository<User> {
  constructor() {
    super('users');
  }

  // eslint-disable-next-line class-methods-use-this
  fromFirestore(snapshot: QueryDocumentSnapshot): User {
    return new User({
      id: snapshot.id,
      ...snapshot.data(),
    } as IUser);
  }

  onAuthStateChanged(
    nextOrObserver: NextOrObserver<AuthUser>,
    error?: ErrorFn,
    completed?: CompleteFn
  ): Unsubscribe {
    return onAuthStateChanged(getAuth(), nextOrObserver, error, completed);
  }

  createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password).then((userCredentials) => {

      if (userCredentials?.user) {
        this.setDocById(userCredentials.user.uid, {
          firstName: '',
          lastName: '',
          email: userCredentials.user.email,
        } as User);
      }
      return userCredentials;
    });
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(getAuth(), email, password);
  }

  signOut(): Promise<void> {
    return signOut(getAuth());
  }

  checkActionCode(code: string): Promise<ActionCodeInfo> {
    return checkActionCode(getAuth(), code);
  }

  applyActionCode(code: string): Promise<void> {
    return applyActionCode(getAuth(), code);
  }

  reauthenticateWithPassword(password: string): Promise<UserCredential> {
    const user = getAuth().currentUser;
    if (user?.email) {
      return reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email, password));
    }
    throw new Error('user not authorized');
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return confirmPasswordReset(getAuth(), code, newPassword);
  }

  getCurrentUser(): AuthUser | null {
    return getAuth().currentUser;
  }
}
