import {initializeApp} from 'firebase/app';
import {Analytics} from 'firebase/analytics';
import React, {createContext, FC, useEffect, useState} from 'react';
import Spinner from '../shared/components/Spinner';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

export interface FirebaseContextReturn {
  initialized: boolean;
  analytics?: Analytics;
}

export const FirebaseContext = createContext<FirebaseContextReturn>({initialized: false});

export const getFirebaseConfig = () => {
  return firebaseConfig;
};

// @ts-ignore
const FirebaseProvider: FC = ({children}) => {
  const [contextValue, setContextValue] = useState<FirebaseContextReturn>({initialized: false});

  useEffect(() => {
    if (!contextValue.initialized) {
      initializeApp(getFirebaseConfig());
      setContextValue({
        initialized: true,
      });
    }
  }, [contextValue]);

  if (!contextValue.initialized) {
    return (
      <Spinner />
    );
  }

  return (
    <FirebaseContext.Provider value={contextValue}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
