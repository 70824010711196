import {
  addDoc, collection, CollectionReference, deleteDoc, doc, DocumentData, FirestoreDataConverter, getDoc, getDocs,
  getFirestore, PartialWithFieldValue, query, QueryConstraint, QueryDocumentSnapshot, setDoc, SnapshotOptions, updateDoc
} from 'firebase/firestore';
import {Base} from '../models/base';

export abstract class BaseRepository<T extends Base> {
  collectionPath: string;

  protected constructor(collectionPath: string) {
    this.collectionPath = collectionPath;
  }

  abstract fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): T;

  // eslint-disable-next-line class-methods-use-this
  toFirestore(modelObject: PartialWithFieldValue<T>): DocumentData {
    if (modelObject.storableProperties) {
      const result: Partial<T> = {};
      (modelObject.storableProperties as string[]).forEach((key) => {
        // @ts-ignore
        if (modelObject[key] !== undefined) {
          // @ts-ignore
          result[key] = modelObject[key];
        }
      });

      return result;
    }

    return modelObject;
  }

  get converter(): FirestoreDataConverter<T> {
    return {
      toFirestore: this.toFirestore,
      fromFirestore: this.fromFirestore,
    };
  }

  get collection(): CollectionReference<T> {
    return collection(getFirestore(), this.collectionPath).withConverter<T>(this.converter);
  }

  async getAll(queryConstraints: QueryConstraint[] = []): Promise<T[]> {
    const q = query(this.collection, ...queryConstraints);
    const snapshot = await getDocs(q);

    return snapshot.docs.map((document) => document.data());
  }

  async getOne(id: string): Promise<T | undefined> {
    const snapshot = await getDoc(this.getDocumentReference(id));
    return snapshot.data();
  }

  setDocById(id: string, document: T): Promise<void> {
    return setDoc(this.getDocumentReference(id), document);
  }

  updateDocById(id: string, document: Partial<T>): Promise<void> {
    return updateDoc(this.getDocumentReference(id), this.toFirestore(document));
  }

  async create(data: T): Promise<T> {
    const t = await addDoc(this.collection, data);

    return {
      ...data,
      id: t.id,
    };
  }

  delete(id: string): Promise<void> {
    return deleteDoc(this.getDocumentReference(id));
  }

  getDocumentReference(id: string) {
    return doc(getFirestore(), this.collectionPath, id).withConverter<T>(this.converter);
  }
}
