import {IUser} from '../interfaces/user';
import {Base} from './base';

export class User extends Base implements IUser {
  storableProperties = [
    'firstName',
    'lastName',
    'email',
  ];
  firstName: string;
  lastName: string;
  email: string;

  constructor({
    id,
    firstName,
    lastName,
    email,
  }: IUser) {
    super(id);

    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  search(searchString: string): boolean {
    return this.email.toLowerCase().includes(searchString)
      || this.firstName.toLowerCase().includes(searchString)
      || this.lastName.toLowerCase().includes(searchString);
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
