import {User as AuthUser} from 'firebase/auth';
import React, {FC, useState} from 'react';
import {useHistory} from 'react-router-dom';
import useFirestore from '../hooks/useFirestore';
import useRepository from '../hooks/useRepository';
import {User} from '../models/user';
import HomeRoutes from '../pages/home/routes';
import {Permissions} from './permissions';

export interface Session {
  loading: boolean;
  isLoggedIn: boolean;
  permissions?: Permissions;
  firebaseUser?: AuthUser;
  user?: User;
  logout: (silent?: boolean) => void;
  updateUser: () => void;
  afterLoginRedirectTarget: string;
  setAfterLoginRedirectTarget: (targetUrl: string) => void;
  stripePortalUrl: string | null;
  setStripePortalUrl: (url: string | null) => void;
}

const defaultSession = {
  loading: true,
  isLoggedIn: false,
  permissions: new Permissions(),
  logout: () => {},
  updateUser: () => {},
  afterLoginRedirectTarget: '',
  setAfterLoginRedirectTarget: () => {},
  stripePortalUrl: null,
  setStripePortalUrl: () => {},
};

export const SessionContext = React.createContext<Session>(defaultSession);

// @ts-ignore
const SessionProvider: FC = ({children}) => {
  const userRepository = useRepository('users');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState<AuthUser>();
  const [user, setUser] = useState<User>();
  const [afterLoginRedirectTarget, setAfterLoginRedirectTarget] = useState('');
  const [permissions, setPermissions] = useState<Permissions>(new Permissions());
  const [stripePortalUrl, setStripePortalUrl] = useState<string | null>(null);

  const logout = () => {
    userRepository.signOut().then(() => history.push(HomeRoutes.index()));
  };

  const updateUser = () => {
    if (user?.id) {
      userRepository.getOne(user?.id).then((u) => {
        setUser(u);
      });
    }
  };

  useFirestore(() => {
    userRepository.onAuthStateChanged((fu) => {
      if (fu?.uid) {
        setFirebaseUser(fu);
        userRepository.getOne(fu.uid).then((u) => {
          setUser(u);
          if (u) {
            setPermissions(new Permissions(u));
            setLoading(false);
            setIsLoggedIn(true);
          } else {
            setPermissions(new Permissions());
            setLoading(false);
            setIsLoggedIn(false);
          }
        });
      } else {
        setPermissions(new Permissions());
        setUser(undefined);
        setFirebaseUser(undefined);
        setLoading(false);
        setIsLoggedIn(false);
      }
    });
  });

  return (
    <SessionContext.Provider
      value={{
        isLoggedIn,
        loading,
        user,
        firebaseUser,
        logout,
        updateUser,
        afterLoginRedirectTarget,
        setAfterLoginRedirectTarget,
        permissions,
        stripePortalUrl,
        setStripePortalUrl,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
