import React, {FC} from 'react';
import HomeRoutes from './pages/home/routes';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import CraftWebsitesRoutes from './pages/craftWebsites/routes';
import CraftWebsitesRouter from './pages/craftWebsites/CraftWebsitesRouter';
import WordpressWebsitesRoutes from './pages/wordpressWebsites/routes';
import WordpressWebsitesRouter from './pages/wordpressWebsites/WordpressWebsitesRouter';
import AuthRoutes from './pages/auth/routes';
import AuthRouter from './pages/auth/AuthRouter';
import FirebaseProvider from './contexts/firebase';
import HomeRouter from './pages/home/HomeRouter';


const App: FC = () => {
  return (
    // @ts-ignore
    <FirebaseProvider>
      <Router>
        <Switch>
          <Route exact path={HomeRoutes.index()} component={HomeRouter}/>
          <Route path={CraftWebsitesRoutes.index()} component={CraftWebsitesRouter}/>
          <Route path={WordpressWebsitesRoutes.index()} component={WordpressWebsitesRouter}/>
          <Route path={AuthRoutes.index()} component={AuthRouter}/>
          <Route component={HomeRouter}/>
        </Switch>
      </Router>
    </FirebaseProvider>
  );
};

export default App;
