import {useContext} from 'react';
import {RepositoriesContext, RepositoriesContextReturn} from '../contexts/repositories';

const useRepository = <R extends keyof RepositoriesContextReturn>(repositoryName: R): RepositoriesContextReturn[R] => {
  const context = useContext(RepositoriesContext);

  return context[repositoryName];
};

export default useRepository;
