import {EffectCallback, useEffect} from 'react';
import useFirebase from './useFirebase';

const useFirestore = (effect: EffectCallback) => {
  const firebaseContext = useFirebase();

  useEffect(() => {
    if (firebaseContext.initialized) {
      effect();
    }
  }, [firebaseContext.initialized]);
};

export default useFirestore;
