import Logo from '../assets/images/semabit_logo.png';
import React, {useState} from 'react';
import {Session} from '../contexts/session';
import useSession from '../hooks/useSession';
import {useLocation} from 'react-router';
import {NavLink, useHistory} from 'react-router-dom';
import CraftWebsitesRoutes from '../pages/craftWebsites/routes';
import WordpressWebsitesRoutes from '../pages/wordpressWebsites/routes';
import {getAuth, signOut} from 'firebase/auth';
import AuthRoutes from '../pages/auth/routes';
import {Button} from 'react-bootstrap';


export const systemNavigation = (session: Session, currentLocation: string) => ([
  {
    label: 'Craft',
    to: () => CraftWebsitesRoutes.index(),
    isVisible: !session.isLoggedIn
  },
  {
    label: 'Wordpress',
    to: () => WordpressWebsitesRoutes.index(),
    isVisible: !session.isLoggedIn
  },
]);

const Navigation = () => {
  const session = useSession();
  const location = useLocation();
  const history = useHistory();
  const auth = getAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(auth.currentUser !== null);

  auth.onAuthStateChanged((user) => {
    setIsLoggedIn(user !== null);
  });

  return (
    <div
      className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
      <a href={'/'}>
        <img src={Logo} height={60} alt={'semabit GmbH'}/>
      </a>
      <nav className="my-2 my-md-0 mr-md-3 ms-auto">
        <div className={'d-flex flex-row align-items-center'}>
          {isLoggedIn && (
            systemNavigation(session, location.pathname)
              .filter((entry) => entry.isVisible)
              .map((entry) => (
                <NavLink
                  key={entry.label}
                  className={'nav-link mx-3'}
                  to={entry.to}
                >
                  {entry.label}
                </NavLink>
              ))
          )}
          {isLoggedIn && (
            <Button
              key={'Logout'}
              className={'mx-3'}
              onClick={() => {
                signOut(auth).then(() => history.push(AuthRoutes.index()));
              }}
            >
              Logout
            </Button>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
