import React, {FC, useEffect, useState} from 'react';
import {Route, RouteComponentProps, Switch, useHistory} from 'react-router-dom';
import WordpressWebsites from './WordpressWebsites';
import WordpressWebsitesRoutes from './routes';
import AuthRoutes from '../auth/routes';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import Spinner from '../../shared/components/Spinner';

interface WordpressWebsitesRouterProps extends RouteComponentProps<WordpressWebsitesRouterParams> {}

interface WordpressWebsitesRouterParams {
  id: string;
}

const WordpressWebsitesRouter: FC<WordpressWebsitesRouterProps> = ({match}) => {
  const auth = getAuth();
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(user !== null);

      if (!user) {
        history.push(AuthRoutes.index());
      }
    });

    return () => unsubscribe();
  }, [auth, history]);

  if (!auth || !isLoggedIn) {
    return (
      <Spinner />
    );
  }

  return (
    <Switch>
      <Route exact path={WordpressWebsitesRoutes.index()} component={WordpressWebsites}/>
    </Switch>
  );
};

export default WordpressWebsitesRouter;
