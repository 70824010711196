import React, { FC, useState, useEffect } from 'react';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import Home from './Home';
import HomeRoutes from './routes';
import AuthRoutes from '../auth/routes';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Spinner from '../../shared/components/Spinner';

interface HomeRouterProps extends RouteComponentProps<HomeRouterParams> {}

interface HomeRouterParams {
  id: string;
}

const HomeRouter: FC<HomeRouterProps> = ({ match }) => {
  const auth = getAuth();
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(user !== null);

      if (!user) {
        history.push(AuthRoutes.index());
      }
    });

    return () => unsubscribe();
  }, [auth, history]);

  if (!auth || !isLoggedIn) {
    return (
      <Spinner />
    );
  }

  return (
    <Route exact path={HomeRoutes.index()} component={Home} />
  );
};

export default HomeRouter;
