import React, {FC} from 'react';
import MainLayout from '../../layouts/MainLayout';
import useCollection from '../../hooks/useCollection';
import {CraftWebsite} from '../../models/craftWebsite';
import {WordpressWebsite} from '../../models/wordpressWebsite';
import Spinner from '../../shared/components/Spinner';
import {Table} from 'react-bootstrap';
import {WebsiteStates} from '../../interfaces/website';
import {PieChart, Pie, Cell, Legend, ResponsiveContainer} from 'recharts';

const Home: FC = () => {

  const {
    collection: craftWebsites,
    isLoading: craftIsLoading
  } = useCollection<CraftWebsite>('craftWebsites');

  const {
    collection: wordpressWebsites,
    isLoading: wordpressIsLoading
  } = useCollection<WordpressWebsite>('wordpressWebsites');

  const uniquePhpVersionsCraft: string[] = [];
  const phpVersionCountsCraft = new Map<string, number>();

  craftWebsites.forEach(website => {
    const phpVersion = website.phpVersion;
    if (phpVersion) {
      if (!uniquePhpVersionsCraft.includes(phpVersion)) {
        uniquePhpVersionsCraft.push(phpVersion);
      }
      const count = phpVersionCountsCraft.get(phpVersion) || 0;
      phpVersionCountsCraft.set(phpVersion, count + 1);
    }
  });

  const phpVersionDataCraft = uniquePhpVersionsCraft.map(phpVersion => ({
    name: phpVersion,
    value: phpVersionCountsCraft.get(phpVersion) || 0,
  }));

  const uniquePhpVersionsWordpress: string[] = [];
  const phpVersionCountsWordpress = new Map<string, number>();

  wordpressWebsites.forEach(website => {
    const phpVersion = website.phpVersion;
    if (phpVersion) {
      if (!uniquePhpVersionsWordpress.includes(phpVersion)) {
        uniquePhpVersionsWordpress.push(phpVersion);
      }
      const count = phpVersionCountsWordpress.get(phpVersion) || 0;
      phpVersionCountsWordpress.set(phpVersion, count + 1);
    }
  });

 const phpVersionDataWordpress = uniquePhpVersionsWordpress.map(phpVersion => ({
    name: phpVersion,
    value: phpVersionCountsWordpress.get(phpVersion) || 0,
  }));

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <MainLayout>
      <div className={'container py-5'}>
        <div className={'row mb-5 gap-5'}>
          <div className={'col-12'}>
            <h2 className={'mb-4'}>Craft Webseiten</h2>
            <>
              {craftIsLoading && (
                <Spinner/>
              )}
              {!craftIsLoading && (
                <>
                  <div className={'row'}>
                    <div className={'col-12 col-lg-6'}>
                      <h3 className={'h6 mb-4'}>PHP Versionen</h3>
                      <ResponsiveContainer width={'100%'} height={300}>
                        <PieChart>
                          <Legend verticalAlign={'top'} align={'left'} height={36}/>
                          <Pie
                            data={phpVersionDataCraft}
                            cx={120}
                            cy={100}
                            innerRadius={60}
                            outerRadius={80}
                            fill={'#8884d8'}
                            paddingAngle={5}
                            dataKey={'value'}
                            nameKey={'name'}
                            label={true}
                            legendType={'line'}
                          >
                            {phpVersionDataCraft.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <Table className={'mb-3'} striped>
                    <tbody>
                    <tr>
                      <th>Anzahl Webseiten</th>
                      <td>{craftWebsites.length}</td>
                    </tr>
                    <tr>
                      <th>PHP Versionen</th>
                      <td>{Array.from(new Set(craftWebsites.map((website) => website.phpVersion))).join(', ')}</td>
                    </tr>
                    <tr>
                      <th>Fehlerhafte Webseiten</th>
                      <td>{craftWebsites.filter((website) => website.state === WebsiteStates.ERROR).length}</td>
                    </tr>
                    </tbody>
                  </Table>
                </>
              )}
            </>
          </div>
          <div className={'col-12'}>
            <h2 className={'mb-4'}>Wordpress Webseiten</h2>
            <>
              {wordpressIsLoading && (
                <Spinner/>
              )}
              {!wordpressIsLoading && (
                <>
                  <div className={'row'}>
                    <div className={'col-12 col-lg-6'}>
                      <h3 className={'h6 mb-4'}>PHP Versionen</h3>
                      <ResponsiveContainer width={'100%'} height={300}>
                        <PieChart>
                          <Legend verticalAlign={'top'} align={'left'} height={36}/>
                          <Pie
                            data={phpVersionDataWordpress}
                            cx={120}
                            cy={100}
                            innerRadius={60}
                            outerRadius={80}
                            fill={'#8884d8'}
                            paddingAngle={5}
                            dataKey={'value'}
                            nameKey={'name'}
                            label={true}
                            legendType={'line'}
                          >
                            {phpVersionDataWordpress.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <Table striped>
                    <tbody>
                    <tr>
                      <th>Anzahl Webseiten</th>
                      <td>{wordpressWebsites.length}</td>
                    </tr>
                    <tr>
                      <th>PHP Versionen</th>
                      <td>{Array.from(new Set(wordpressWebsites.map((website) => website.phpVersion))).join(', ')}</td>
                    </tr>
                    <tr>
                      <th>Fehlerhafte Webseiten</th>
                      <td>{wordpressWebsites.filter((website) => website.state === WebsiteStates.ERROR).length}</td>
                    </tr>
                    </tbody>
                  </Table>
                </>

              )}
            </>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;
