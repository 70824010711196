import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AuthRoutes from './routes';
import Auth from './Auth';

const AuthRouter = () => (
  <Switch>
    <Route path={AuthRoutes.index()} component={Auth}/>
  </Switch>
);

export default AuthRouter;
