import React, {FC} from 'react';
import {WebsiteStates} from '../../interfaces/website';
import {faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export interface SpinnerProps {
  state: WebsiteStates;
}

const WebsiteStateBadge: FC<SpinnerProps> = ({state}) => {

  return (
    <>
      {state === WebsiteStates.OK && (
        <span className="badge rounded-pill bg-success">
          <FontAwesomeIcon icon={faCheck}/>
        </span>
      )}
      {state === WebsiteStates.ERROR && (
        <span className="badge rounded-pill bg-danger">
          <FontAwesomeIcon icon={faXmark}/>
        </span>
      )}
    </>
  );
};

export default WebsiteStateBadge;
