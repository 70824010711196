import React from 'react';
import {useLocation} from 'react-router';
import MainLayout from '../../layouts/MainLayout';
import useFirebase from '../../hooks/useFirebase';
import SignIn from './partials/SignIn';
import AuthRoutes from './routes';

const Auth = () => {
  const firebaseContext = useFirebase();
  const location = useLocation();

  if (!firebaseContext.initialized) {
    return null;
  }

  return (
    <MainLayout className={'auth'}>
      {location.pathname === AuthRoutes.index() && <SignIn/>}
    </MainLayout>
  );
};

export default Auth;
