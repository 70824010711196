import React, {FC, useState} from 'react';
import {Alert, Button, Form} from 'react-bootstrap';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import HomeRoutes from '../../home/routes';
import {useHistory} from 'react-router-dom';

interface SignInProps {
  redirect?: boolean;
}

const SignIn: FC<SignInProps> = ({}) => {
  const [authing, setAuthing] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const auth = getAuth();
  const history = useHistory();

  const logInWithEmailAndPassword = async () => {
    setAuthing(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      history.push(HomeRoutes.index());
    } catch (e) {
      setError(true);
      setAuthing(false);

      setTimeout(function () {
        setError(false);
      }, 4000);
    }
  };

  return (
    <>
      <div className={'container py-5'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <h1 className={'mb-3'}>Login</h1>
            <Form>
              <Form.Group className={'mb-3'}>
                <Form.Label>E-Mail</Form.Label>
                <Form.Control
                  type={'text'}
                  name={'email'}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text className="text-muted"/>
              </Form.Group>
              <Form.Group className={'mb-3'}>
                <Form.Label>Passwort</Form.Label>
                <Form.Control
                  type={'password'}
                  name={'password'}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Text className="text-muted"/>
              </Form.Group>
              <Button type={'submit'} variant={'primary'} onClick={() => logInWithEmailAndPassword()}
                      disabled={authing}>
                Login
              </Button>
            </Form>

            {error && (
              <Alert className={'mt-4'} variant={'danger'} onClose={() => setError(false)} dismissible>
                <Alert.Heading>Ooops!</Alert.Heading>
                <p>
                  Leider konntest du nicht eingeloggt werden! Überprüfe bitte deine Eingaben und versuche es dann
                  erneut.
                </p>
              </Alert>
            )}
          </div>
        </div>
      </div>


    </>
  );
};

export default SignIn;
