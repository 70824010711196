import {IBase} from "./base";
import {IPlugin} from './plugin';

export enum WebsiteStates {
  OK = 'ok',
  ERROR = 'error'
}

export interface IWebsite extends IBase {
    name: string;
    url: string;
    environment: string;
    phpVersion?: string;
    frameworkVersion?: string;
    plugins?: IPlugin[];
    state?: WebsiteStates;
}
