import React, {FC, useEffect, useState} from 'react';
import {Route, RouteComponentProps, useHistory} from 'react-router-dom';
import CraftWebsites from './CraftWebsites';
import CraftWebsitesRoutes from './routes';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import AuthRoutes from '../auth/routes';
import Spinner from '../../shared/components/Spinner';

interface CraftWebsitesRouterProps extends RouteComponentProps<CraftWebsitesRouterParams> {}

interface CraftWebsitesRouterParams {
  id: string;
}

const CraftWebsitesRouter: FC<CraftWebsitesRouterProps> = ({match}) => {
  const auth = getAuth();
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(user !== null);

      if (!user) {
        history.push(AuthRoutes.index());
      }
    });

    return () => unsubscribe();
  }, [auth, history]);

  if (!auth || !isLoggedIn) {
    return (
      <Spinner />
    );
  }

  return (
    <Route exact path={CraftWebsitesRoutes.index()} component={CraftWebsites}/>
  );
};

export default CraftWebsitesRouter;
