import {QueryDocumentSnapshot} from 'firebase/firestore';
import {BaseRepository} from './baseRepository';
import {WordpressWebsite} from "../models/wordpressWebsite";

export class WordpressWebsiteRepository extends BaseRepository<WordpressWebsite> {
    constructor() {
        super('wordpress-websites');
    }

    // eslint-disable-next-line class-methods-use-this
    fromFirestore(snapshot: QueryDocumentSnapshot): WordpressWebsite {
        return new WordpressWebsite({
            id: snapshot.id,
            ...snapshot.data(),
        } as WordpressWebsite);
    }
}
