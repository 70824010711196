import {useState} from 'react';
import {QueryConstraint} from 'firebase/firestore';
import useFirestore from './useFirestore';
import useRepository from './useRepository';
import {Base} from '../models/base';
import {RepositoriesContextReturn} from '../contexts/repositories';
import {BaseRepository} from '../repositories/baseRepository';

const useCollection = <T extends Base>(
  repositoryName: keyof RepositoriesContextReturn,
  queryConstraints?: QueryConstraint[]
) => {
  const repository = useRepository(repositoryName) as unknown as BaseRepository<T>;
  const [collection, setCollection] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const load = () => {
    setIsLoading(true);
    repository.getAll(queryConstraints).then((entries) => {
      setCollection(entries);
      setIsLoading(false);
    });
  };

  useFirestore(() => {
    load();
  });

  return {
    collection,
    setCollection,
    isLoading,
    repository,
    load,
  };
};

export default useCollection;
