import {Base} from './base';
import {IWebsite, WebsiteStates} from '../interfaces/website';
import {IPlugin} from '../interfaces/plugin';

export class WordpressWebsite extends Base implements IWebsite {
  storableProperties = [
    'name',
    'url',
    'environment',
    'phpVersion',
    'frameworkVersion',
    'plugins',
    'state',
  ];
  name: string;
  url: string;
  environment: string;
  phpVersion?: string;
  frameworkVersion?: string;
  plugins?: IPlugin[];
  state?: WebsiteStates;

  constructor({
    id,
    name,
    url,
    environment,
    phpVersion,
    frameworkVersion,
    plugins,
    state,
  }: IWebsite) {
    super(id);
    this.name = name;
    this.url = url;
    this.environment = environment;
    this.phpVersion = phpVersion;
    this.frameworkVersion = frameworkVersion;
    this.plugins = plugins || [];
    this.state = state;
  }
}


