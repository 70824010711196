import React, {FC, useState} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {CraftWebsite} from '../../models/craftWebsite';
import useCollection from '../../hooks/useCollection';
import {IPlugin} from '../../interfaces/plugin';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsRotate, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Accordion, Table} from 'react-bootstrap';
import {WebsiteStates} from '../../interfaces/website';
import WebsiteStateBadge from '../../shared/components/WebsiteStateBadge';
import Spinner from '../../shared/components/Spinner';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from 'recharts';

const CraftWebsites: FC = () => {
  const [isFetching, setIsFetching] = useState(false);
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  let {
    collection: craftWebsites,
    load: loadData,
    repository: craftWebsitesRepository,
  } = useCollection<CraftWebsite>('craftWebsites');

  const reloadCraftData = () => {
    setIsFetching(true);
    const promises: any = [];

    craftWebsites.forEach((craftWebsite) => {
      const promise = fetch('https://' + craftWebsite.url + '/actions/versions/info/system', {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_PLUGIN_API_KEY}`
        }
      })
        .then((response) => response.json())
        .then((json) => {
          craftWebsite.frameworkVersion = json.craft;
          craftWebsite.phpVersion = json.php;
          craftWebsite.state = WebsiteStates.OK;
          let plugins: IPlugin[] = [];
          let pluginsData = json.plugins;
          {
            Object.keys(pluginsData).forEach((key) => {
              plugins.push({
                name: key,
                version: pluginsData[key]
              } as IPlugin);
            });
          }

          craftWebsite.plugins = plugins;
          craftWebsitesRepository.updateDocById(craftWebsite.id, craftWebsite);
        })
        .catch(() => {
          craftWebsite.state = WebsiteStates.ERROR;
          craftWebsitesRepository.updateDocById(
            craftWebsite.id, craftWebsite
          );
        });

      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      loadData();
      setIsFetching(false);
    });
  };

  const uniquePhpVersionsCraft: string[] = [];
  const phpVersionCountsCraft = new Map<string, number>();

  craftWebsites.forEach(website => {
    const phpVersion = website.phpVersion;
    if (phpVersion) {
      if (!uniquePhpVersionsCraft.includes(phpVersion)) {
        uniquePhpVersionsCraft.push(phpVersion);
      }
      const count = phpVersionCountsCraft.get(phpVersion) || 0;
      phpVersionCountsCraft.set(phpVersion, count + 1);
    }
  });

  const phpVersionDataCraft = uniquePhpVersionsCraft.map(phpVersion => ({
    name: phpVersion,
    value: phpVersionCountsCraft.get(phpVersion) || 0,
  }));

  return (
    <>
      {isFetching && (
        <MainLayout className={'py-5 text-center'}>
          <Spinner/>
        </MainLayout>
      )}
      {!isFetching && (
        <MainLayout className={'py-5'}>
          <div className={'container'}>
            <div className={'row mb-5'}>
              <div className={'col-12'}>
                <div className={'d-flex'}>
                  <h1 className={'display-4 mb-4'}>Craft Webseiten</h1>
                  <div className={'ms-auto'}>
                    <button type="button" className="btn btn-primary me-2" onClick={() => reloadCraftData()}>
                      Aktualisieren <FontAwesomeIcon className={'ms-2'} icon={faArrowsRotate}/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 col-lg-6'}>
                <h3 className={'h6 mb-4'}>PHP Versionen</h3>
                <ResponsiveContainer width={'100%'} height={300}>
                  <PieChart>
                    <Legend verticalAlign={'top'} align={'left'} height={36}/>
                    <Pie
                      data={phpVersionDataCraft}
                      cx={120}
                      cy={100}
                      innerRadius={60}
                      outerRadius={80}
                      fill={'#8884d8'}
                      paddingAngle={5}
                      dataKey={'value'}
                      nameKey={'name'}
                      label={true}
                      legendType={'line'}
                    >
                      {phpVersionDataCraft.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className={'col-12'}>
                <Table striped bordered hover>
                  <thead>
                  <tr>
                    <th scope="col">Webseite</th>
                    <th scope="col">Url</th>
                    <th scope="col">Craft Version</th>
                    <th scope="col">PHP Version</th>
                    <th scope="col">Plugins</th>
                    <th scope="col">Status</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {craftWebsites.map((website: any) => (
                    <tr key={website.name}>
                      <td className={'align-middle'}>{website.name}</td>
                      <td className={'align-middle'}><a href={'https://' + website.url} target={'_blank'}
                                                        rel={'noreferrer'}>{website.url}</a>
                      </td>
                      <td className={'align-middle'}>{website.frameworkVersion}</td>
                      <td className={'align-middle'}>{website.phpVersion}</td>
                      <td className={'align-middle'}>
                        {website.plugins.length >= 1 && (
                          <Accordion>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>{website.plugins.length} Plugins installiert</Accordion.Header>
                              <Accordion.Body className={'p-0'}>
                                <Table striped>
                                  <tbody>
                                  {website.plugins.map((plugin: IPlugin) => (
                                    <tr>
                                      <td>
                                        {plugin.name}
                                      </td>
                                      <td className={'text-end'}>
                                        {plugin.version}
                                      </td>
                                    </tr>
                                  ))}
                                  </tbody>
                                </Table>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )}
                      </td>
                      <td className={'align-middle text-center'}>
                        <WebsiteStateBadge state={website.state}/>
                      </td>
                      <td className={'align-middle text-end'}>
                        <button
                          type="button" className="btn btn-danger"
                          onClick={() => craftWebsitesRepository.delete(website.id)}
                        >
                          <FontAwesomeIcon icon={faTrash}/>
                        </button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          {/*{addingRecord && (*/}
          {/*  <AddWebsiteModal*/}
          {/*    onSuccess={() => {*/}
          {/*      loadData();*/}
          {/*      setAddingRecord(false);*/}
          {/*    }}*/}
          {/*    onCancel={() => setAddingRecord(false)}*/}
          {/*    framework={'wordpress'}*/}
          {/*    show={addingRecord}*/}
          {/*  />*/}
          {/*)}*/}
        </MainLayout>
      )}
    </>
  );
};

export default CraftWebsites;
