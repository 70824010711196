import React, {FC, ReactNode} from 'react';
import Navigation from '../shared/Navigation';
import Footer from '../shared/Footer';

export interface MainLayoutProps {
  className?: string;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({
  className = '',
  header = <Navigation/>,
  footer = <Footer/>,
  children,
}) => {
  return (
    <>
      <header>
        {header}
      </header>
      <div className={`content ${className}`}>
        {children}
      </div>
      {footer}
    </>
  );
};

export default MainLayout;
