import React from 'react';

const Footer = () => {

  return (
    <div className={'container mt-auto'}>
      <footer className="pt-4 my-md-5 pt-md-5 border-top">
        <div className="row">
          <div className="col-12 fst-italic small">
            semabit website versions v1.0
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
